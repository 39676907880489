<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-md-12" style="margin-bottom: 24px">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <h3 v-if="$route.params.id"><strong>Edit Device</strong></h3>
                        <h3 v-else><strong>Add Device</strong></h3>
                    </div>
                    <div class="panel-body">
                        <Form @submit="deviceSubmit" :initial-values="deviceFormData" :validation-schema="deviceSchema">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="name">Device Name</label>
                                    <Field type="text" id="device_name" class="form-control" name="device_name" />
                                    <ErrorMessage class="error_alert" name="device_name" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="device_model">Device Model</label>
                                    <Field type="text" id="device_model" class="form-control" name="device_model" />
                                    <ErrorMessage class="error_alert" name="device_model" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="cpu_core">CPU Core</label>
                                    <Field type="text" id="cpu_core" class="form-control" name="cpu_core" />
                                    <ErrorMessage class="error_alert" name="cpu_core" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="ram">RAM (IN GB)</label>
                                    <div class="input-group">
                                        <Field v-model="ram" type="text" id="ram" class="form-control" name="ram" />
                                    </div>
                                    <ErrorMessage class="error_alert" name="ram" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="storage">Storage</label>
                                    <div class="input-group">
                                        <Field v-model="storage" type="text" id="storage" class="form-control"
                                            name="storage" />
                                        <select class="form-select dropdown-small" v-model="storageUnit"
                                            aria-label="Storage unit" style="width:20px;">
                                            <option value="GB">GB</option>
                                            <option value="TB">TB</option>
                                        </select>
                                    </div>
                                    <ErrorMessage class="error_alert" name="storage" />
                                </div>
                                <div class="form-group col-md-4">
                                    <label for="os">OS</label>
                                    <Field type="text" id="os" class="form-control" name="os" />
                                    <ErrorMessage class="error_alert" name="os" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="os_version">OS Version</label>
                                    <Field type="text" id="os_version" class="form-control" name="os_version" />
                                    <ErrorMessage class="error_alert" name="os_version" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="source_id">Source Guardian ID</label>
                                    <Field type="text" id="source_guardian_id" class="form-control"
                                        name="source_guardian_id" />
                                    <ErrorMessage class="error_alert" name="source_guardian_id" />
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <router-link type="button" to="/device-main-table" class="btn btn-danger mr-2"
                                        style="margin-right:20px;">BACK</router-link>
                                    <!-- <button v-if="$route.params.id" type="submit"
                                        class="btn btn-primary">UPDATE</button>
                                    <button v-else type="submit" class="btn btn-primary">SAVE</button> -->
                                    <button v-if="$route.params.id" :disabled="loading" type="submit"
                                        class="btn btn-primary">
                                        {{ loading ? 'UPDATING...' : 'UPDATE' }}
                                    </button>
                                    <button v-else :disabled="loading" type="submit" class="btn btn-primary">
                                        {{ loading ? 'SAVING...' : 'SAVE' }}
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script setup>
import { onBeforeMount, ref } from 'vue';
import { defineRule, Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const router = useRouter();
const route = useRoute();
const param_id = ref([]);
const errors = ref({ tag: [] });
const deviceFormData = ref({
    device_name: '',
    device_model: '',
    cpu_core: '4',
    ram: '4',
    storage: '256',
    os: 'Debian',
    os_version: '11',
    source_guardian_id: ''
});
const storageUnit = ref('GB')
const loading = ref(false);

const deviceSchema = yup.object().shape({
    device_name: yup.string().required('This field is required.'),
    device_model: yup.string().required('This field is required.'),
    cpu_core: yup.string()
        .required('This field is required.')
        .matches(/^[\d]+( ?core)?$/, 'Invalid CPU core format. Use only digits and optional "core". Example: 4 core'),

    ram: yup.string()
        .required('This field is required.')
        .matches(/^\d+$/, 'Invalid RAM format.Use only digits. Example: 8GB'),

    storage: yup.string()
        .required('This field is required.')
        .matches(/^\d+$/, 'Invalid storage format. Use only digits. Example: 500GB'),
    os: yup.string()
        .required('This field is required.')
        .matches(/^[a-zA-Z\s]+$/, 'Invalid OS format. Use only letters and spaces.'),
    os_version: yup.string()
        .required('This field is required.')
        .matches(/^[\d]+(\.[\d]+)*$/, 'Invalid OS version format. Use digits separated by periods. Example: 10.1'),
    source_guardian_id: yup.string().required('This field is required.'),
});

onBeforeMount(() => {
    const queryParam = route.params.id;
    if (queryParam !== undefined) {
        param_id.value = queryParam;
        store.dispatch('editDevice', { id: param_id.value }).then((response) => {
            if (response.status == 200) {
                deviceFormData.value = response.data.data;
                deviceFormData.value.ram = deviceFormData.value.ram.replace("GB", "");

                deviceFormData.value.storage = deviceFormData.value.storage
                    .replace("GB", "")
                    .replace("TB", "");
            }
        });
    }
});
function deviceSubmit(values) {
    loading.value = true;
    values.ram = values.ram + "GB"
    values.storage = values.storage + storageUnit.value
    if (!route.params.id) {
        store.dispatch('device', values).then((res) => {
            if (res.data.errors) {
                errors.value = res.data.errors;
            }

            if (res.status == 200) {
                showMessage(res.data.message, 'success');
                router.push('/device-main-table');
            } else {
                showMessage(res.data.message, 'warning');
            }
        });
    } else {
        values['id'] = route.params.id;
        store.dispatch('updateDevice', values).then((res) => {
            if (res.data.errors) {
                errors.value = res.data.errors;
            }
            if (res.status == 200) {
                showMessage(res.data.message, 'success');
                router.push('/device-main-table');
            } else {
                showMessage(res.data.message, 'warning');
            }
        });
    }
    loading.value = false;

}



const showMessage = (msg, type) => {
    const toast = window.Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
    });
    toast.fire({
        icon: type,
        title: msg,
        padding: '10px 20px',
    });
};
</script>

<style>
.tag-input {
    width: 100%;
    border: 1px solid #eee;
    font-size: 0.9em;
    height: 50px;
    box-sizing: none;
    padding: 0 10px;
}

.tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #5C6BC0;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
}

.h23 {
    margin: 20px;
}

.date-pckr {
    background-color: #f1f2f300 !important;
    color: #060818;
}

.m-12 {
    margin: 5px;
}

.btn btn-outline-primary mb-2 me-1 {
    width: 50%;
}
</style>
