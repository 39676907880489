<template>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="col-md-12" style="margin-bottom: 24px">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <h3 v-if="$route.params.id"><strong>Edit Software</strong></h3>
            <h3 v-else><strong>Add Software</strong></h3>
          </div>
          <div class="panel-body">
            <Form @submit="softwareSubmit" :initial-values="softwareFormData" :validation-schema="softwareSchema">
              <div class="row">
                <div class="form-group col-md-3">
                  <label for="name">Software Name</label>
                  <Field type="text" name="name" id="name" class="form-control" />
                  <ErrorMessage class="error_alert" name="name" />
                  <p class="error_alert" v-for="err in errors.name" :key="err.id">{{ err }}</p>
                </div>
                  <div class="form-group col-md-3">
                  <label for="name">Description</label>
                  <Field type="text" name="description" id="description" class="form-control" />
                  <ErrorMessage class="error_alert" name="description" />
                  <p class="error_alert" v-for="err in errors.description" :key="err.id">{{ err }}</p>
                </div>
              </div>
              <router-link type="button" to="/software-main-table" class="btn btn-danger m-12">BACK</router-link>
              <button v-if="$route.params.id" type="submit" class="btn btn-primary">Update</button>
              <button v-else type="submit" class="btn btn-primary">Save</button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount } from 'vue';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import Multiselect from '@suadelabs/vue3-multiselect';
import { defineRule, Field, Form, ErrorMessage, FieldArray, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
const store = useStore();
const router = useRouter();
const route = useRoute();

const param_id = ref([]);
const code_arr = ref([]);
const errors = ref({});


const softwareFormData = ref({
  name: '',
  description:'',
});
const softwareSchema = yup.object().shape({
  name: yup.string().required('This field is required.'),
  description:yup.string().required('This field is required.'),

});

onBeforeMount(() => {
  const queryParam = route.params.id;
  if (queryParam !== undefined) {
    param_id.value = queryParam;
    store.dispatch('editSoftware', { id: param_id.value }).then((response) => {
      if (response.status == 200) {
        softwareFormData.value = response.data.data;
   

      }
    });
  }
});

function softwareSubmit(values) {
  console.log(values);
  if (!route.params.id) {
    store.dispatch('software', values).then((res) => {
      console.log(res);
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage('success', res.data.message);
        router.push('/software-main-table');

        this.$emit('softwareUpdated');
      } else {
        showMessage('warning', res.data.message);
      }
    });
  } else {
    values['id'] = route.params.id;
    store.dispatch('updateSoftware', values).then((res) => {
      console.log(res);
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage('success', res.data.message);
        router.push('/software-main-table');

        this.$emit('softwareUpdated');
      } else {
        showMessage('warning', res.data.message);
      }
    });
  }
}

const showMessage = (type, msg) => {
  const toast = window.Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
  });
  toast.fire({
    icon: type,
    title: msg,
    padding: '10px 20px',
  });
};
</script>
<style scoped>
.h23 {
  margin: 20px;
}

.date-pckr {
  background-color: #f1f2f300 !important;
  color: #060818;
}

.m-12 {
  margin: 5px;
}
</style>
