<template>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="col-md-12" style="margin-bottom: 24px">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <h3 v-if="$route.params.id"><strong>Edit Release</strong></h3>
            <h3 v-else><strong>Add Release</strong></h3>
          </div>

          <div class="panel-body">

            <Form @submit="releasesSubmit" :initial-values="releasesFormData"
              :validation-schema="$route.params.id ? updateReleasesSchema : releasesSchema">
              <div class="row">

                <div class="form-group col-md-3">
                  <label for="software_id">Software</label>
                  <Field name="software_id" as="select" class="form-control">
                    <option value="" disabled selected>Software Name</option>
                    <option v-for=" software in softwares " :key="software.id" :value="software.id">
                      {{ software.name }}</option>
                  </Field>
                  <ErrorMessage class="error_alert" name="software_id" />
                  <p class="error_alert" v-for=" err in errors.sofware_id " :key="err.id">{{ err }}</p>
                </div>

                <div class="form-group col-md-3">
                  <label for="version">Version</label>
                  <Field type="text" name="version" id="version" class="form-control" />
                  <ErrorMessage class="error_alert" name="version" />
                  <p class="error_alert" v-for=" err in errors.version " :key="err.id">{{ err }}</p>
                </div>
                <div class="form-group col-md-3">
                  <label for="description">Description</label>
                  <Field type="text" name="description" id="description" class="form-control" />
                  <ErrorMessage class="error_alert" name="description" />
                  <p class="error_alert" v-for=" err in errors.description " :key="err.id">{{ err }}</p>
                </div>

                <div class="form-group col-md-3" v-if="!$route.params.id">
                  <label for="file">Upload File</label>
                  <Field type="file" id="file" class="form-control" v-model="fileStream" name="file"
                    @change="uploadFile" />
                  <ErrorMessage class="error_alert" name="file" />
                  <p class="error_alert" v-for=" err in errors.file " :key="err.id">{{ err }}</p>
                </div>
              </div>
              <router-link type="button" to="/releases-main-table" class="btn btn-danger m-12">BACK</router-link>
              <button v-if="$route.params.id" :disabled="isSubmitting" type="submit"
                class="btn btn-primary">Update</button>
              <button v-else type="submit" :disabled="isSubmitting" class="btn btn-primary">Save</button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount } from 'vue';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import Multiselect from '@suadelabs/vue3-multiselect';
import { defineRule, Field, Form, ErrorMessage, FieldArray, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
const store = useStore();
const router = useRouter();
const route = useRoute();
const software_id_option = ref([]);
const param_id = ref([]);
const code_arr = ref([]);
const errors = ref({});
const releasesFormData = ref({
  software_id: '',
  version: '',
  description: '',
  file: '',
  params_id: route.params.id
});

const softwares = ref([]);

const baseSchema = yup.object().shape({
  software_id: yup.string().required('This field is required.'),
  version: yup.string().required('This field is required.'),
  description: yup.string().required('This field is required.').max(255, 'Description must not exceed 255 characters.'),
});

const releasesSchema = baseSchema.shape({
  file: yup.mixed().transform((v) => (!v ? undefined : v)).required('This field is required.'),
});
const updateReleasesSchema = baseSchema;

const isSubmitting = ref(false);
const queryParam = route.params.id;
onBeforeMount(async () => {

  const softwareResponse = await store.dispatch('getSoftwareList', {});
  softwares.value = softwareResponse.data;
  if (route.params.id) {
    ``

    const response = await store.dispatch('editReleases', { id: route.params.id });
    if (response.status === 200) {
      releasesFormData.value = response.data.data
      console.log(releasesFormData.value)
    }
  }
});

const fileStream = ref('')
const uploadFile = (e) => {
  console.log('ji')
  fileStream.value = e.target.files[0]
  console.log(e.target.files[0]);
  if (fileStream) {
    console.log(fileStream.value)
  }
  else {
    console.log("helloo")
  }

};

function releasesSubmit(values) {
  console.log(values);
  console.log(fileStream.value);
  isSubmitting.value = true;
  values["file"] = fileStream.value;

  if (!route.params.id) {
    store.dispatch('releases', values).then((res) => {
      console.log(res);
      isSubmitting.value = false;
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage('success', res.data.message);
        router.push('/releases-main-table');
      } else {
        showMessage('warning', res.data.message);
      }
    });
  } else {
    values['id'] = route.params.id;

    console.log(values)
    store.dispatch('updateReleases', values).then((res) => {
      isSubmitting.value = false;
      console.log(res);
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage('success', res.data.message);
        router.push('/releases-main-table');
      } else {
        showMessage('warning', res.data.message);
      }
    });
  }
}

const showMessage = (type, msg) => {
  const toast = window.Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
  });
  toast.fire({
    icon: type,
    title: msg,
    padding: '10px 20px',
  });
};
</script>

<style>
.h23 {
  margin: 20px;
}

.date-pckr {
  background-color: #f1f2f300 !important;
  color: #060818;
}

.m-12 {
  margin: 5px;
}
</style>