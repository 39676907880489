<template>
  <div class="layout-px-spacing">
    <div class="row layout-top-spacing">
      <div class="col-md-12" style="margin-bottom: 24px">
        <div class="statbox panel box box-shadow">
          <div class="panel-heading">
            <h3 v-if="$route.params.id"><strong>Edit Deployments</strong></h3>
            <h3 v-else><strong>Add Deployments</strong></h3>
          </div>
          <div class="panel-body">
            <Form @submit="deploymentSubmit" :initial-values="deploymentFormData" :validation-schema="deploymentSchema">
              <div class="row">

                <!-- <div class="form-group col-md-4">
                    <label for="name">Device ID</label>
                    <Field type="text" id="device_id" class="form-control" name="device_id" />
                    <ErrorMessage class="error_alert" name="device_id" />
                    <p class="error_alert" v-for="err in errors.device_id" :key="err.id">{{ err }}</p>
                  </div> -->
                <div class="form-group col-md-3">
                  <label for="device_id">Device Name</label>
                  <Field type="text" name="device_id" id="device_id" class="form-control" as="select">
                    <option v-for="items in device_id_option" :key="item.id" :value="item.value">{{
                      item.text.toUpperCase()
                    }}</option>
                  </Field>
                  <ErrorMessage class="error_alert" name="device_id" />
                  <p class="error_alert" v-for="err in errors.device_id" :key="err.id">{{ err }}</p>
                </div>
                <div class="form-group col-md-3">
                  <label for="release_id"> Version</label>
                  <Field type="text" name="release_id" id="release_id" class="form-control" as="select">
                    <option v-for="items in version_option" :key="item.id" :value="item.value">{{ item.text.toUpperCase()
                    }}</option>
                  </Field>
                  <ErrorMessage class="error_alert" name="release_id" />
                  <p class="error_alert" v-for="err in errors.release_id" :key="err.id">{{ err }}</p>
                </div>
                <!-- <div class="form-group col-md-4">
                    <label for="release_id">Release ID</label>
                    <Field type="text" id="release_id" class="form-control" name="release_id" />
                    <ErrorMessage class="error_alert" name="release_id" />
                    <p class="error_alert" v-for="err in errors.release_id" :key="err.id">{{ err }}</p>
                  </div> -->
              </div>
              <router-link type="button" to="/deployment-main-table" class="btn btn-danger m-12">BACK</router-link>
              <button v-if="$route.params.id" type="submit" class="btn btn-primary">UPDATE</button>
              <button v-else type="submit" class="btn btn-primary">SAVE</button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script setup>
import { onMounted, ref, onBeforeMount } from 'vue';
import { defineRule, Field, Form, ErrorMessage, FieldArray, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';




const device_id_option = ref([]);
const version_option = ref([])
//   const default_shift_option = ref([]);
const store = useStore();
const router = useRouter();
const route = useRoute();
const param_id = ref([]);
const errors = ref({});
const deploymentFormData = ref({
  device_id: '',
  release_id: '',

});

const deploymentSchema = yup.object().shape({
  device_id: yup.string().required('This field is required.'),
  release_id: yup.string().required('This field is required.'),

});


onBeforeMount(() => {
  store.dispatch('default_credentials').then((res) => {
    console.log(res);
    device_id_option.value = res.data.devices.map((el) => ({
      value: el.id,
      text: el.name,
    }));
    version_option.value = res.data.releases.map((el) => ({
      value: el.id,
      text: el.version,
    }));
  });

});



const queryParam = route.params.id;
if (queryParam !== undefined) {
  param_id.value = queryParam;
  store.dispatch('editDeployments', { id: param_id.value }).then((response) => {
    if (response.status == 200) {
      deploymentFormData.value = response.data.deployment;
      console.log(response.data.deployment)

    }
  });
}

function deploymentSubmit(values) {
  console.log(values);
  if (!route.params.id) {
    store.dispatch('deployments', values).then((res) => { //deplyments here is the name of the function
      console.log(res);
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage(res.data.message, 'success');
        router.push('/deployment-main-table');
      } else {
        showMessage(res.data.message, 'warning');
      }
    });
  } else {
    values['id'] = route.params.id;
    store.dispatch('updateDeployments', values).then((res) => {
      console.log(res);
      if (res.data.errors) {
        errors.value = res.data.errors;
      }
      if (res.status == 200) {
        showMessage(res.data.message, 'success');
        router.push('/deployment-main-table');
      } else {
        showMessage(res.data.message, 'warning');
      }
    });
  }
}

const showMessage = (msg, type) => {
  const toast = window.Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
  });
  toast.fire({
    icon: type,
    title: msg,
    padding: '10px 20px',
  });
};
</script>
<style >
.h23 {
  margin: 20px;
}

.date-pckr {
  background-color: #f1f2f300 !important;
  color: #060818;
}

.m-12 {
  margin: 5px;
}
</style>