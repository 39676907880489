<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel br-6 p-0">
                    <router-link to="/customers-main-form">
                        <button type="button" class="btn btn-primary add-btn1">ADD</button>
                    </router-link>
                    <div class="custom-table">
                        <v-server-table :columns="columns" :options="customerMain" :key="recall">
                            <template #customer_name="props">
                                <span class="text-uppercase">{{ props.row.customer_name }}</span>
                            </template>

                            <template #company_name="props">
                                <span class="text-uppercase">{{ props.row.company_name }}</span>
                            </template>

                            <template #country="props">
                                <span class="text-uppercase">{{ props.row.country.name }}</span>
                            </template>

                            <template #state="props">
                                <span class="text-uppercase">{{ props.row.state.name }}</span>
                            </template>

                            <template #city="props">
                                <span class="text-uppercase">{{ props.row.city.name }}</span>
                            </template>

                            <template #software="props">
                                <span class="text-uppercase">{{ props.row.software.name }}</span>
                            </template>

                            <template #purchase_code="props">
                                <span class="text-uppercase">{{ props.row.purchase_code }}</span>
                            </template>

                            <template #purchase_date="props">
                                <span class="text-uppercase">{{ props.row.purchase_date }}</span>
                            </template>

                            <template #Contact_No.="props">
                                <span class="text-uppercase">{{ props.row.contact_no }}</span>
                            </template>

                            <template #expiry_date="props">
                                <span class="text-uppercase">{{ props.row.expiry_date }}</span>
                            </template>

                            <template #device="props">
                                <span class="text-uppercase">{{ props.row.device.device_name }}</span>
                            </template>

                            <template #actions="props">
                                <div class="me-2 custom-dropdown btn-group">
                                    <router-link class="btn btn-icon-only"
                                        :to="{ name: 'edit-customers-main-form', params: { id: props.row.id } }">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" margin-right="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit">
                                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                        </svg>
                                    </router-link>
                                    <button class="btn btn-icon-only" @click="deleteCustomerFun(props.row.id)">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" margin-left="20"
                                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                            stroke-linecap="round" stroke-linejoin="round"
                                            class="feather feather-trash">
                                            <polyline points="3 6 5 6 21 6"></polyline>
                                            <path
                                                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </template>

                        </v-server-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useMeta } from '@/composables/use-meta';
import { useRouter, useRoute } from 'vue-router';

const store = useStore();
const columns = ref(['customer_name', 'company_name', 'country', 'state', 'city', 'software', 'purchase_code', 'purchase_date', 'expiry_date', 'contact_no', 'device', 'actions']);
const recall = ref(0);

const customerMain = ref({
    perPage: 5,
    perPageValues: [5, 10, 25],
    skin: 'table',
    columnsClasses: { actions: 'actions text-center' },
    sortable: ['customer_name', 'company_name', 'country_id', 'state_id', 'city_id', 'software_id', 'purchase_code', 'purchase_date', 'expiry_date', 'contact_no', 'device_id'],
    sortIcon: {
        base: 'sort-icon-none',
        up: 'sort-icon-asc',
        down: 'sort-icon-desc',
    },
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
        count: 'Showing {from} to {to} of {count}',
        filter: '',
        filterPlaceholder: 'Search...',
        limit: 'Results:',
    },
    resizableColumns: false,
    async requestFunction(data) {
        const pagination = data;
        const direction = data.ascending == 1 ? 'asc' : 'desc';
        data.direction = direction;
        data.orderBy = data.orderBy ? data.orderBy : 'name';
        return store.dispatch(`getCustomer`, pagination).then((res) => {
            return { data: res.data.data.data, count: res.data.data.total };
        });
    },
});

const deleteCustomerFun = (id) => {
    new window.Swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Delete',
        padding: '2em',
    }).then((result) => {
        if (result.value) {
            store.dispatch('deleteCustomer', id).then((response) => {
                if (response.status == 200) {
                    recall.value += 1;
                    showAlertMessage(response.data.message, 'success');
                } else {
                    showAlertMessage(response.data.message, 'warning');
                }
            });
        }
    });
};

const showAlertMessage = (msg, type) => {
    const toast = window.Swal.mixin({ toast: true, position: 'top', showConfirmButton: false, timer: 3000 });
    toast.fire({ icon: type, title: msg, padding: '10px 20px' });
};
</script>

<style>
.add-btn1 {
    margin-left: 20px;
    margin-top: 20px;
}
</style>
