

<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-md-12" style="margin-bottom: 24px">
                <div class="statbox panel box box-shadow">
                    <div class="panel-heading">
                        <h3><strong>Update Password</strong></h3>
                    </div>

                    <div class="panel-body">
                        <Form @submit="updatePassword" :initial-values="passwordFormData"
                            :validation-schema="passwordSchema">
                            <div class="row">
                                <div class="form-group col-md-4">
                                    <label for="currentPassword">Current Password</label>
                                    <Field type="password" id="currentPassword" class="form-control"
                                        name="currentPassword" />
                                    <ErrorMessage class="error_alert" name="currentPassword" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="newPassword">New Password</label>
                                    <Field type="password" id="newPassword" class="form-control" name="newPassword" />
                                    <ErrorMessage class="error_alert" name="newPassword" />
                                </div>

                                <div class="form-group col-md-4">
                                    <label for="confirmPassword">Confirm Password</label>
                                    <Field type="password" id="confirmPassword" class="form-control"
                                        name="confirmPassword" />
                                    <ErrorMessage class="error_alert" name="confirmPassword" />
                                </div>

                                <div class="row">
                                    <div class="col-md-12">
                                        <button type="submit" class="btn btn-primary">Update Password</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, onBeforeMount } from 'vue';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import Multiselect from '@suadelabs/vue3-multiselect';
import { defineRule, Field, Form, ErrorMessage, FieldArray, useFieldArray } from 'vee-validate';
import * as yup from 'yup';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
const store = useStore();
const router = useRouter();
const route = useRoute();

const passwordFormData = ref({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
});

const passwordSchema = yup.object().shape({
    currentPassword: yup.string().required('This field is required.'),
    newPassword: yup.string().required('This field is required.'),
    confirmPassword: yup.string().required('This field is required.').oneOf([yup.ref('newPassword'), null], 'Passwords must match.'),
});

const updatePassword = (values) => {

    console.log('Updating password:', values);

};
</script>

<style>
.h23 {
    margin: 20px;
}

.date-pckr {
    background-color: #f1f2f300 !important;
    color: #060818;
}

.m-12 {
    margin: 5px;
}
</style>