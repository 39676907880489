import axios from 'axios';

// import {createStore} from 'vuex';

const state = {};
const getters = {};
const mutations = {};
const actions = {
    device: async ({ commit }, getData) => {
        console.log(getData);
        return await axios
            .post('/devices', getData)
            .then((resp) => {
                console.log(resp);
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },
    getDevice: async ({ commit, dispatch }, getData) => {
        console.log(getData);
        return await axios
            .get(`/devices?query=${getData.query}&sort_direction=${getData.direction}&sort_by=${getData.orderBy}&page=${getData.page}&per_page=${getData.limit}`)
            .then((response) => {
                return response;
            })
            .catch((error) => {
                return error.response;
            });
    },
    updateDevice: async ({ commit, dispatch }, getData) => {
        console.log(getData);
        return await axios
            .put(`/devices/${getData.id}`, getData)
            .then((res) => {
                console.log(res);
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    editDevice: async ({ commit }, getData) => {
        console.log(getData);
        return await axios
            .get(`/devices/${getData.id}`)
            .then((res) => {
                console.log(res);
                return res;
            })
            .catch((error) => {
                return error.response;
            });
    },
    deleteDevice: async ({ commit }, id) => {
        return await axios
            .delete(`/devices/${id}`)
            .then((resp) => {
                return resp;
            })
            .catch((error) => {
                return error.response;
            });
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
