<template>
    <div class="layout-px-spacing">
        <div class="row layout-top-spacing">
            <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
                <div class="panel br-6 p-0">
                    <div class="custom-table">
                        <v-server-table :columns="columns" :options="softwareLogs" :key="recall">

                            <template #software="props">
                                <span>{{ props.row.software }}</span>
                            </template>

                            <template #version="props">
                                <span>{{ props.row.version }}</span>
                            </template>

                            <template #customer="props">
                                <span>{{ props.row.customer }}</span>
                            </template>

                            <template #ip_address="props">
                                <span>{{ props.row.ip_address }}</span>
                            </template>

                            <template #log_type="props">
                                <span>{{ props.row.log_type }}</span>
                            </template>

                            <template #time="props">
                                <span>{{ props.row.time }}</span>
                            </template>
                        </v-server-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import axios from 'axios';

const columns = ref(['software', 'version', 'customer', 'ip_address', 'log_type', 'time']);
const recall = ref(0);

const softwareLogs = ref({

    headings: {
        software: 'Software',
        version: 'Version',
        customer: 'Customer',
        ip_address: 'IP Address',
        log_type: 'Log Type',
        time: 'Time'
    },
    perPage: 5,
    perPageValues: [5, 10, 25],
    skin: 'table',
    pagination: { nav: 'scroll', chunk: 5 },
    texts: {
        count: 'Showing {from} to {to} of {count}',
        filter: '',
        filterPlaceholder: 'Search...',
        limit: 'Results:',
    },
    resizableColumns: false,
    async requestFunction(data) {

        const response = await axios.get(`/software/logs`, {
            params: {
                query: data.query,
                sort_direction: data.direction,
                sort_by: data.orderBy,
                page: data.page,
                per_page: data.limit,
            },
        });

        return {
            data: response.data.data.data,
            count: response.data.data.total,
        };
    },
});
</script>

<style></style>